<template>
	<div>
		<a-modal title="选择商品" :width="480" :visible="visible" @cancel="handleCancel" :footer="null">
			<a-spin :spinning="loading">
				<div>
					<div class="flex center">
						<a-input-search placeholder="搜索商品名称" v-model="search.name" style="width: 420px" @search="onSearch" />
					</div>
					
					<div class="mt20">
						<div class="flex wrap center" v-if="datas.length>0">
							<div class="edit-flash-sale-goods-it-item flex space" v-for="(item,index) in datas">
								<div class="flex">
									<img v-if="item.cover_img != null" :src="item.cover_img"/>
									<div class="ml10">
										<div class="ft14 ftw400 cl-black text-over4">{{item.name}}</div>
										<div class="ft12 ftw400 cl-notice mt15 ">门市原价：¥{{item.original_price}}</div>
									</div>
								</div>
								
								<div class="flex alcenter center" v-if="JSON.stringify(value) !== '{}' && item.goods_id==value.goods_id">
									<div class="edit-flash-sale-goods-it-item-duihao  flex center alcenter cl-theme">
										<i class="iconfont iconduihao ft12"/>
									</div>
								</div>
								<div class="flex alcenter center" v-else>
									<div class="edit-flash-sale-goods-it-item-select  flex center alcenter cl-w ft14 ftw600 clickAct" @click="checkIt(index)">
										选择
									</div>
								</div>
							</div>
						</div>
						
						<div v-else class="flex center mt20">
							<a-empty/>
						</div>
					</div>
					
					<div class="flex center mt20">
						<a-pagination @change="pageChange" :total="pagination.total" v-model="pagination.current" :pageSize="pagination.pageSize" show-less-items />
					</div>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import {listMixin} from '../../../../../common/mixin/list.js';
	export default {
		props: {
			visible: {
				type: Boolean,
				default: false,
			},
			value:{
				type:Object,
				default:function(){
					return new Object
				},
			}
		},
		mixins:[listMixin],
		data() {
			return {
				loading: false,
				confirmLoading: false,
				pagination:{
					total:0,
					pageSize:4,
					current:1,
				},
				search:{
					name:'',
				},
				datas:[],
				
			}
		},
		
		methods: {
			
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				let goods_id= JSON.stringify(this.value) !== '{}' ? this.value.gooods_id : 0;
				this.$http.api('admin/chooseGoods',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
					name:this.search.name,
					type:'pin_tuan',
					goods_id:goods_id,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			onSearch(value){
				this.page=1;
				this.getLists()
			},

			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
			
			checkIt(index){
				if (this.loading == true) return;
				this.loading = true;
				let value=this.datas[index];
				this.$emit('input',value);
				this.$emit('ok');
			}
		}
	}
</script>

<style>
	.edit-flash-sale-goods-it-item{
		width: 410px;
		height: 80px;
		background: #FFFFFF;
		box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.04);
		border-radius: 4px;
		margin-bottom: 20px;
		padding: 20px;
	}
	
	.edit-flash-sale-goods-it-item img{
		width: 60px;
		height: 60px;
	}
	
	.edit-flash-sale-goods-it-item-duihao{
		width: 60px;
		height: 32px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #4772FF;
	}
	.edit-flash-sale-goods-it-item-select{
		width: 60px;
		height: 32px;
		background: #4772FF;
		border-radius: 4px;
		cursor: pointer;
	}
</style>
